<nav class="navbar navbar-expand-lg navbar-dark bg-color">
  <div class="container-fluid" [ngClass]="{'p-0': isMobileViewVisible}">
    <div class="navbar-header" [ngClass]="{'width-70percentage': isMobileViewVisible}">
      <!-- <a class="navbar-brand fontSize">{{ practiceName }}</a> -->
      <a class="navbar-head-Text">{{ practiceName }}</a>
    </div>
    <button class="navbar-toggler bg-color"
      style="margin-right: 17px;border: none;"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="18" viewBox="0 0 29 18" fill="none">
      <path d="M2 16H27M2 9H27M2 2H27" stroke="white" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
      <!-- <span class="navbar-toggler-icon"></span> -->
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-left: 19px;">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="invoices" routerLinkActive="active"
            (click)="closeMenu()"
            >Invoice</a
          >
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="patient-history"
            routerLinkActive="active"
            (click)="closeMenu()"
            >Payment History</a
          >
        </li>
      
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="submenu1"
            data-bs-toggle="dropdown"
            routerLinkActive="active"
            >{{ patientName }}</a
          >
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item pointer" style="text-align: center;" (click)="logOut()">Log Out</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
